import Auth from '@aws-amplify/auth';
import { API_URL } from '../global';

type getFetchParams = {
    data?: any,
    auth?: boolean,
    api?: string,
    headers?: any,
    method?: string,
    key?: string,
};

export const getFetch = async (url: string, { data, auth = true, api, headers = {}, method = 'GET' }: getFetchParams) => {
    if (!(['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].includes(method)))
        throw new Error('Invalid method provided.');

    let opts: RequestInit = {
        method: method,
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', ...headers }
    };

    if (['POST', 'PUT', 'PATCH'].includes(method)) {
        if (!data)
            throw new Error('Missing data!');
        opts.body = data;
    }

    if (auth) {
        const session = await Auth.currentSession();
        opts.headers['Authorization'] = session.getIdToken().getJwtToken();
    }

    const full_url = (api || API_URL) + url;
    return fetch(full_url, opts);
};

// Function to load data from \a url, and update the \a state with it.
// This function presumes that \a state has format such as: { data: Any, loaded: bool, error: false or String }.
// If \a device_id is \a "none", function sets \a state.loaded and \a state.error to \a false,
// and exits.
// Function \a extractData is called on data retrieval, and its output
// is set as \a state.data, using \a setState function.
export async function loadData(url, device_id, extractData, state, setState, auth = true) {
    if (device_id == null) {
        setState({
            ...state,
            loaded: false,
            error: false
        });
        return
    }

    try {
        let options: RequestInit = { mode: 'cors' }
        if (auth) {
            const session = await Auth.currentSession();
            options['headers'] = { 'Authorization': session.getIdToken().getJwtToken() }
        }
        const resp = await fetch(API_URL + url, options);
        const data = await resp.json();
        if (resp.ok) {
            setState({
                ...state,
                data: extractData(data),
                loaded: true,
                error: false
            });
        } else {
            setState({
                ...state,
                loaded: false,
                error: data.Message,
            });
        }
    }
    catch (error) {
        setState({
            ...state,
            loaded: false,
            error: String(error),
        });
    }
}

type postDataParams = {
    data: any,
    auth?: boolean,
    api?: string,
    method?: string
};

// :param url is relative path from API
// :param options object may contain:
// - data - literal data to send; if sending object, make sure to JSON.stringify() [required]
// - auth - boolean, defaults to true (needs to be explicitly turned off)
// - api - provide a different API base URL, other than config.API_URL
// - method - one of ['POST', 'PUT', 'PATCH'], default 'POST'
// :returns response data, or raises Error
export async function postData(url: string, { data, auth = true, api, method = 'POST' }: postDataParams): Promise<Response> {
    if (!data)
        throw new Error('Missing data!');

    if (!(['POST', 'PUT', 'PATCH'].includes(method)))
        throw new Error('Invalid method provided.');

    let opts: RequestInit = {
        method: method,
        mode: 'cors',
        body: data,
        headers: { "Content-Type": "application/octet-stream" }
    };
    if (auth) {
        const session = await Auth.currentSession();
        opts.headers['Authorization'] = session.getIdToken().getJwtToken();
    }

    const full_url = url;

    try {
        // then and catch.
        const resp = await fetch(full_url, opts);
    
        if (resp.ok) {
          return resp;
        } else {
          const rdata = await resp.json();
          throw new Error(rdata.Message);
        }
      } catch (error) {
        console.log(error);
        throw new Error("An error occurred during the request...");
      }
}
