// MUI Material v5.;
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const CustomizedToolTip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 18,
    backgroundColor: "rgba(10, 190, 55, 0.63)",
  },
}));

const longText = `
Critical: I can't do any labelling.
High: I can't work as expected.
Medium: I can work but not so optimal.
`;

export default function ToolTipSeverities() {
  return (
      <CustomizedToolTip title={longText} placement="right">
        <IconButton>
          <HelpOutlineIcon />
        </IconButton>
      </CustomizedToolTip>
  );
}
