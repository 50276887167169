import { useForm } from 'react-hook-form';
import useFetch from '../utils/useFetch';
import Modal from './Modal';
import { useEffect, useState } from 'react';
// Amplify.
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import ToolTipStatuses from './toolTips/ToolTipStatuses';
import ToolTipSeverities from './toolTips/ToolTipSeverities';
import "./styles/Form.css"

const MAX_FILENAME_LENGTH = 35;

const AddOrEditTicketModal = ({add, hide, editTicket, getLink, data}) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  useEffect(() => {reset(data);}, [data]);
  useEffect(() => {
    // Delay the invocation of generateLinks (miliseconds)
    const delay = setTimeout(() => {
      generateLinks();
    }, 500);
    return () => clearTimeout(delay);
  }, []);

  const [existingScreenshotsLoaded, setExistingScreenshotsLoaded] = useState(false);

  const handleDeleteScreenshot = async (divElement, screenshot) => {
    const object_name = screenshot.name;
    divElement.remove();
    data.data.saved_screenshot = data.data.saved_screenshot.filter(screenshot => screenshot.name != object_name);
  };


  const generateLinks = async () => {
    try {
      const container = document.getElementById('existingScreenshotsContainer');

      if (container) {  
        setExistingScreenshotsLoaded(false); // Show loading state
        await Promise.all(
          data.data.saved_screenshot.map(async (screenshot) => {
            const presignedUrl = await getLink(screenshot.name);
              if (presignedUrl) {
                  const linkElement = document.createElement('a');
                  linkElement.href = presignedUrl;
                  linkElement.textContent = `${screenshot.name.length > MAX_FILENAME_LENGTH ? screenshot.name.substring(0, MAX_FILENAME_LENGTH) + "..." : screenshot.name}`;
                  linkElement.textContent = linkElement.textContent.replaceAll("%20", " ");

                  // Create delete button
                  const deleteButton = document.createElement('button');
                  deleteButton.textContent = 'X';
                  deleteButton.className = 'fileDeleteButton'
      
                  const paragraphElement = document.createElement('p');
                  paragraphElement.appendChild(linkElement);
                  paragraphElement.appendChild(deleteButton); // Append delete button
                  paragraphElement.className = 'screenshotList';
                  const divElement = document.createElement('div');
                  divElement.appendChild(paragraphElement);
                  deleteButton.onclick = () => handleDeleteScreenshot(divElement, screenshot);
                  container.appendChild(divElement);
              }
          })
      );      

        setExistingScreenshotsLoaded(true); // Hide loading state
      }
    } catch (error) {
      console.error('Error generating links:', error);
    }
  };
  
  const ticketsdata = useFetch(`/tickets`, {extract: data => data.tickets});
  
  if (!ticketsdata.loaded) {
    return (
        <Modal handleClose={hide} show={true} title={add ? 'Add Ticket' : 'Edit Ticket'}>
          {ticketsdata.error ? 'Failed to load! ' + ticketsdata.error : 'Loading...'}
        </Modal>
    );
  }
  
  return (
    <Modal handleClose={hide} show={true} title={add ? 'Add Ticket' : 'Edit Ticket'} width="50em" Height="120px">
      <form onSubmit={handleSubmit(editTicket)}>
        {add ? '' : <input name="id" className="full" hidden={true} type="text" {...register} />}
      
        <div style={{display:"flex"}}>
          <div style={{width:"50%"}}>       
            <div style={{paddingBottom:"2em"}}>
              <label htmlFor="status" style={add?{visibility: "hidden"}:{visibility:"visible"}}>Status<ToolTipStatuses /></label>
              <select name="status" {...register('status')} style={add?{visibility: "hidden"}:{visibility:"visible"}} defaultValue="New">
                <option value="New">New</option>
                <option value="Inprog">Inprog</option>
                <option value="Closed">Closed</option>
              </select>
            </div>
              
            <div style={{paddingBottom:"2em", display: "inline-block"}}>
              <label htmlFor="data.title">Problem Title</label>
              {errors?.data?.title && <span className="error">Required!</span>}
              <textarea type="text" name="data.title" {...register('data.title', { required: true })} />
            </div>

            <div style={{paddingBottom:"2em"}}>
              <label htmlFor="data.description">Problem Description</label>
              { errors?.data?.description && <span className="error">Required!</span>}
              <textarea type="text" name="data.description" placeholder="Please provide the email of the agent having the problem."
              {...register('data.description', { required: true})} />
            </div>

            <div style={{paddingBottom:"1.5em"}}>
              <label htmlFor="data.steps_to_reproduce">Steps to reproduce</label>
              { errors?.data?.steps_to_reproduce && <span className="error">Required!</span>}
              <textarea type="text" name="data.steps_to_reproduce" {...register('data.steps_to_reproduce', { required: true })} />
            </div>

            <div style={{float:"left"}}>
              <button className="addTicketButton">{add ? "Create" : "Edit"}</button>
            </div>
          </div>
        
          <div style={{width:"50%", paddingLeft:"1em"}}>
            <div style={{paddingBottom:"2em"}}>
              <label htmlFor="severity">Severity<ToolTipSeverities /></label>
              <select name="severity" {...register('severity')}>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
                <option value="Critical">Critical</option>
              </select>
            </div>

            <div style={{paddingBottom:"2em"}}>
              <label htmlFor="data.expected_behaviour">Expected Behaviour</label>
              { errors?.data?.expected_behaviour && <span className="error">Required!</span>}
              <textarea type="text" name="data.expected_behaviour" {...register('data.expected_behaviour', { required: true })} />
            </div>

            <div style={{paddingBottom:"2em"}}>
              <label htmlFor="data.resulted_behaviour">Resulted Behaviour</label>
              { errors?.data?.resulted_behaviour && <span className="error">Required!</span>}
              <textarea type="text" name="data.resulted_behaviour" {...register('data.resulted_behaviour', { required: true })} />
            </div>

            <div>
              <label htmlFor="data.uploaded_screenshot">Upload screenshot(s)</label>
              { errors?.data?.uploaded_screenshot && <span className="error">Required!</span>}
              <input type="file" name="data.uploaded_screenshot" {...register('data.uploaded_screenshot', { required: add })} multiple/>
              <p style={{ marginTop: '5px' }}>Hold 'Ctrl' (Windows) or 'Command' (Mac) for bulk selection.</p>
            </div>

            {add ? '' : 
              <div id="existingScreenshotsContainer">
              <label>Existing Screenshots:</label>
              {existingScreenshotsLoaded ? (
                data.data.uploaded_screenshot.length === 0 ? (
                  <p>No uploaded images</p>
                ) : null
              ) : (
                <p>Loading...</p>
              )}
            </div>
            }

        </div>
      </div>
    </form>
  </Modal>
  )
}

export default withAuthenticator(AddOrEditTicketModal);