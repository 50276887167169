export const loadFileAsBinary = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onloadend = event => {
    const result = event.target.result;
    if (result instanceof ArrayBuffer) {
      resolve(result);
    } else {
      reject(new Error('Failed to load file as ArrayBuffer.'));
    }
  };
  reader.onerror = e => reject(e);
  reader.readAsArrayBuffer(file);
});


export const loadFileAsText = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onloadend = event => resolve(event.target.result);
  reader.onerror = e => reject(e);
  reader.readAsText(file);
})
