import ReactTooltip from 'react-tooltip';

const LabellingAccuracyTooltip = () => <ReactTooltip id="labellingAccuracyTableTip" event="click" globalEventOff="click" place="right" type="info" offset={{'top': -50}} arrowColor="transparent">
  <div className="headline">Definitions</div>
  <div className="subheadline">Accounted Task</div>
  <div className="text">Number of tasks for which the trashevent went through Data Validity.<br></br>Only these are accounted in the accuracy calculation.</div>
  <div className="subheadline">Correct Task</div>
  <div className="text">Number of tasks among the Accounted Tasks for which the final labels are the same as the labels set by the agent.</div>
  <div className="subheadline">Accuracy</div>
  <div className="text">Percentage of Correct Tasks among the Accounted Tasks.</div>
  <div className="subheadline">Days Above/Below Threshold</div>
  <div className="text">Number of days spent above or below the accuracy target set for each agent level.
    <ul>
      <li>Level 1: 60%</li>
      <li>Level 2: 70%</li>
      <li>Level 3: 80%</li>
    </ul>
  </div>
  <div className="subheadline">Average Rolling Accuracy</div>
  <div className="text">Accuracy calculated on tasks from the current day and the 6 prior days. Its value is averaged over all the selected agents</div>
</ReactTooltip>;

export default LabellingAccuracyTooltip;