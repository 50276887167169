import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { useDispatch } from 'react-redux';
import useFetch from '../utils/useFetch';
import { getFetch, postData } from '../utils/loadData';
import { errorNotification, successNotification } from '../utils/actions';
import AddOreditTicketModal from './AddorEditTicketModal';
import { useNavigate } from "react-router-dom";
import ToolTipStatuses from './toolTips/ToolTipStatuses';
import ToolTipSeverities from './toolTips/ToolTipSeverities';
import LabellingAccuracyTooltip from './toolTips/NewVerToolTip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import "./styles/Tickets.css"
import { loadFileAsBinary } from '../utils/loadFile';
import QnA from './QnA';
import { ComponentPropsToStylePropsMap } from '@aws-amplify/ui-react';

export default function Tickets() {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPopUp, setShowPopUp] = useState(false)
  
  // Ticket data.
  const [rc, setRC] = useState(0);
  const ticketsdata = useFetch(`/tickets?tickets=${rc}`, {extract: data => data.tickets});
  // -------------------------------------------------

  // Add and edit ticket.
  const [showNT, setShowNT] = useState(false);
  const [showET, setShowET] = useState(false);

  const etData = useRef({});
  const [sendingET, setSendingET] = useState(false);
  
  const onEditTicket = data => {
    console.log("onEditTicket");
    etData.current = data;
    etData.current.data.saved_screenshot = data.data.uploaded_screenshot;
    etData.current.name = data.data.name;
    setShowET(true);
  }

  const getLink = async (image_name) => {
    try {
        const response = await getFetch(`/getImage/${image_name}`, {});
        const presignedUrl = await response.json();
        return presignedUrl;
      } catch (error) {
        console.error('Error fetching image data:', error);
      }
  }

  // Function to check if an object exists in the array
  function doesNotExist(obj, arr) {
    return arr.every(item => item.name !== obj.name);
  }
  const fetchData = async (files) => {
    try {
      var presignedUrls = [];
      for (const file of files) {
        const image_name = file.name;
        const response = await getFetch(`/getUrl/${image_name}`, {});
        const presignedUrl = await response.json();
        presignedUrls.push(presignedUrl);
      }
      return presignedUrls;
    } catch (error) {
      console.error('Error fetching presigned URLs:', error);
      return [];
    }
  };
  

  const editTicket = async data => {
    if (sendingET)
      return;
    setSendingET(true);

    var url = '';
    var method = '';
    var creating = false;

    if (data.id) { // If the data that that we pass to this method has an ID, it recognizes it as a patch request.
      // editing.
      const rid = data.id;
      url = `/tickets/${rid}`;
      method = 'PATCH';
    } else {
      url = `/create`;
      method = 'POST';
      creating = true;
    }

    var files = Array.from(data.data.uploaded_screenshot).filter(file => file instanceof File);
    var presignedUrls = await fetchData(files);
    
    await Promise.all(
      files.map(async (file, index) => {
        const presignedUrl = presignedUrls[index];
        try {
          const content = await loadFileAsBinary(file);
          const response = await postData(presignedUrl, { data: content, method: 'PUT', auth: false });
    
          if (response.ok) {
            console.log("Image uploaded!");
          } else {
            console.log("File failed to upload...");
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          console.log("File failed to upload...");
        }
      })
    );

  files = files.map(file => ({
        name: file.name,
        type: file.type,
        size: file.size,
      }));

  if (!creating && etData.current.data.saved_screenshot.length > 0) {
    // Add elements from existing files to the new array if they do not already exist
    etData.current.data.saved_screenshot.forEach(obj => {
      if (doesNotExist(obj, files)) {
        files.push(obj);
      }
    });
  }
  
  const requestBody = {
    status: data.status,
    severity: data.severity,
    data: {
      title: data.data.title,
      description: data.data.description,
      steps_to_reproduce: data.data.steps_to_reproduce,
      expected_behaviour: data.data.expected_behaviour,
      resulted_behaviour: data.data.resulted_behaviour,
      uploaded_screenshot: files,}
  };

  getFetch(url, { data: JSON.stringify(requestBody), method: method }).
    then(resp => {
      if (resp.ok) {
        resp.json().then(data => dispatch(successNotification(creating ? 'Created successfully!' : 'Edited successfully!')));
        creating ? setShowNT(false) : setShowET(false);
      }
      else
        resp.json().then(data => dispatch(errorNotification(data.Message)));
      }, e => dispatch(errorNotification('Failed!'))).
      finally(() => {
        setRC(rc + 1);
        setSendingET(false);
  });

}

// -------------------------------------------------

// Delete ticket.
  const [Udeleting, setUdeleting] = useState(false);

  const deleteUser = async idx => {
    if (Udeleting)
      return;

    const doDelete = window.confirm(`Are you sure you want to delete this ticket?`);
    if (doDelete) {
      setUdeleting(true);

      // Delete images related to the ticket from S3 Bucket
      const files = Array.from(ticketsdata.data[idx].data.uploaded_screenshot);

      // Loop through the image names and delete each object
      for (const file of files) {
        var filename = JSON.stringify(file.name);
        getFetch(`/deleteImage/${filename}`, {}).
          then(resp => {
            console.log(`Image deleted successfully!`);
          }, e => dispatch(errorNotification(`Failed to deleted image ${filename}!`)));
      };

      // Delete the ticket
      getFetch(`/tickets/${ticketsdata.data[idx].id}`, { method: 'DELETE'}).
        then(resp => {
          dispatch(successNotification(resp.ok ? 'Deleted ticket.' : 'Failed to delete ticket.'));
        }, e => dispatch(errorNotification('Failed!'))).
        finally(() => {
          setRC(rc + 1);
          setUdeleting(false);
        });
  }}


  const onDelete = useCallback(idx => {
    deleteUser(idx);
  }, [ticketsdata]);

  const PopUpBeforeNewTicket = () => {

    return (
    <div style={{textAlign: "left", width: "100%", height: "100%"}}>
      <div style={{position: "absolute", top: "50%", left: "50%", width: "40%", transform: "translate(-50%, -50%)", backgroundColor: "#ffffff", border: "1px solid gray", borderRadius: "0.5em", padding: "1em"}}>
        <h3 style={{textAlign: "center"}}>Pssst! Have you tried this already?</h3>
        <p>
          <b>Have you logged in and are the name of the food items not showing?</b><br />- Try refreshing the page.
        </p>
        <p>
        <b>Are you new and don’t have access to the Tagging Dashboard?</b><br />-You first need to go through the training process to become a level 3.
        </p>
        <p>
        <b>Are you having troubles editing the label?</b><br />- Try refreshing the page.
        </p>
        <p>
        <b>Is your dashboard not loading the batches?</b> <br />- Try refreshing the page.
        </p>
        <p>
        <b>Have you refreshed and the images still not loading?</b><br />-Try another batch and if the problem persists, create a ticket.
        </p>
        <button className='goBackButton' onClick={() => setShowPopUp(false)}>Go back</button>
        <button className='createNewTicketButton' onClick={() => {setShowNT(true);
                                            setShowPopUp(false)}}>Create New Ticket</button>
      </div>
    </div>
    )
    }

// em means that sizes will be proportional.
// -------------------------------------------------
// Rendering.
  return <div>

    {showET ? <AddOreditTicketModal add={false} hide={() => setShowET(false)} editTicket={editTicket} getLink={getLink} data={etData.current} /> : ''}
    {showNT ? <AddOreditTicketModal add={true} hide={() => setShowNT(false)} editTicket={editTicket} data={{}} /> : ''}

    <h1>Tickets Table</h1> 

    <div className='middleSection'>
        <div> 
          <h4>Ticket Statuses<ToolTipStatuses /></h4>
          <h4 id='severities'>Ticket Severities<ToolTipSeverities /></h4>
        </div> 
        <div>
          <button className="addTicketButton" onClick={() => setShowPopUp(true)}>New Ticket</button>
          {showPopUp ? <PopUpBeforeNewTicket /> : ""}
        </div>
    </div>
    {ticketsdata.loaded ?
      <TicketsTable data={ticketsdata.data} onDelete={onDelete} onEditTicket={onEditTicket}/> :
      (ticketsdata.error ? 'Failed to load! ' + ticketsdata.error : 'Loading...')}
  </div>;
}

const TicketsTable = ({ data, onDelete, onEditTicket }) => {
  const columns = useMemo(() => [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Severity', accessor: 'severity' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Created on', accessor: 'created_on' },
    { Header: 'Created by', accessor: 'created_by' },
    { Header: 'Updated on', accessor: 'updated_on' },
    { Header: 'Edit', Cell: ({row}) => <button className="tableButtons" id="edit" onClick={() => onEditTicket(row.original)}>Edit</button>},
    { Header: 'Delete', Cell: ({row}) => <button className="tableButtons" id="delete" onClick={() => onDelete(row.id)}>Delete</button>},
  ], [data, onEditTicket]);
// ], [data, onEdit, onACL]);
// sup tag, defines a superscript text. They are rendered in smaller font and are used for footnotes.
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{id: 'data.id', desc: true}],
      },
    },
    useSortBy,
    usePagination
  );
  // 128 px.
  return (
    <div style={{marginLeft:"8em", marginRight:"8em"}}>
      <table {...getTableProps()} style={{width:"100%"}}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id}>
                  <div {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {column.isSorted ? (column.isSortedDesc ? <span> &#8600;</span> : <span> &#8599;</span>) : ''}
                  </div>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>{" "}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </button>{" "}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </button>{" "}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>{" "}
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            className='pagination_inputs'
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: "100px" }}
          />
        </span>{" "}
        <select
          className='pagination_inputs'
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20, 30, 40].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
};
